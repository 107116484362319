import { FieldMap } from '../../../utility/functions/map-fields';
import { FetchXmlQuery } from './fetch-xml-manager';
 
/**
 * @description Recupera la cutoffdate rispetto ad una data fornitua in input
 * @param effDate
 */
export function getCutOffDateXmlQuery(effDate: string): FetchXmlQuery {
    const entity = 'egl_cutoffdate';
    return {
        entity,
        query: `
		<fetch top="1" no-lock="true">
			<entity name="${entity}">
				<attribute name="egl_date" />
				<order attribute="egl_date" descending="true" />
				<filter type="and">
					<condition attribute="egl_date" operator="on-or-before" value="${effDate}" />
				</filter>
			</entity>
		</fetch>
			`,
    };
}
 
export interface D365CutOffDate {
    egl_date: string;
}
 
export interface SupCutOffDate {
    cutOffDate: string;
}
 
export const D365ToSupCutOffDateFieldMap: FieldMap<D365CutOffDate, SupCutOffDate>[] = [
    { sourceField: 'egl_date', resultField: 'cutOffDate' },
];
