import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AObject, CacheService, PlatformService } from '@congacommerce/core';
import { CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, retry, share, take, tap } from 'rxjs/operators';
import { resetOrderEntry, setPlicoUrl } from '../../../store/actions/order-entry.actions';
import { resetUserState, setCartSegment } from '../../../store/actions/user.actions';
import { FlowType } from '../../../store/models/flow-type';
import { EglState } from '../../../store/reducers';
import { selectFlowType, selectSalesProcess } from '../../../store/selectors/order-entry.selectors';
import { selectAgentInfo, selectCurrentVirtualAgent } from '../../../store/selectors/user.selectors';
import { DOCUMENT_TYPE_CONFIG, SKIP_DOCUMENT_GENERATION } from '../../config/document-ot.config';
import { RoutesPaths } from '../../config/routes-paths';
import { AptCustomerType } from '../../enums/apttus/apt-customer-type';
import { AptSalesProcess } from '../../enums/apttus/apt-sales-process';
import { AptSignatureMode } from '../../enums/apttus/apt-signature-mode';
import { ClearStateFlags } from '../../enums/shared/clear-state-flags';
import { EnumContainsFlag } from '../../functions/misc.functions';
import { ifOp, retryWithDelay } from '../../functions/observable-operators';
import { convertSegmentAptToD365, convertSegmentD365toApt } from '../../functions/remap.functions';
import { flowTypeUtil } from '../../functions/verifications.functions';
import { StatusResponse } from '../../interfaces/base-api-response';
import { SalesforceQueryResult } from '../../models/apttus/salesforce-query-result';
import { EglCartExtended } from '../../models/apttus/tables/cart/egl-cart-extended';
import { EglQuotePAVLight } from '../../models/apttus/tables/pav/egl-quote-p-a-v-light';
import { EglQuoteLight } from '../../models/apttus/tables/quote/egl-quote-light';
import { AgentInfo, VirtualAgent } from '../../models/user/agent';
import { CommonProvider } from '../../providers/common-provider';
import { TelemetryMetricService } from '../app/telemetry-metric.service';
import { LoadingService } from '../shared/loading.service';
import { LoggerService } from '../shared/logger.service';
import { EglPAVLightService } from './tables/egl-pav-light.service';
import { EglQuoteLightService } from './tables/quote/egl-quote-light.service';

@Injectable({
    providedIn: 'root',
})
export class ApttusService {
    public showDropCartModal = new EventEmitter();
    public onCreateNewCartCompleted = new EventEmitter<string>();
    private _cartId: string;

    private itemRemovalInProgress = new BehaviorSubject<boolean>(false);
    currentSubject = this.itemRemovalInProgress.asObservable();

    private configRulesLoaded = new BehaviorSubject<boolean>(false);
    configRulesSubject$ = this.configRulesLoaded.asObservable();
    flowType: FlowType;

    constructor(
        private cartSrv: CartService,
        private router: Router,
        private store: Store<EglState>,
        private logger: LoggerService,
        private eglPAVLightSrv: EglPAVLightService,
        private pltSrv: PlatformService,
        private cacheSrv: CacheService,
        private eglQuoteLightSrv: EglQuoteLightService,
        private commonPrv: CommonProvider,
        private telemetrySrv: TelemetryMetricService,
    ) {}

    /**
     * Aggiornamento di tutti di un blocco di PAV in input
     * @param pavs: PAV da aggiornare
     */
    updatePavs(pavs: EglQuotePAVLight[]): Observable<boolean> {
        const loaderFinalizer = LoadingService.loaderOperator();
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('Update PAV');
        return this.eglPAVLightSrv.update(<AObject[]>pavs).pipe(
            take(1),
            telemetryFinalizer,
            loaderFinalizer,
            tap((res: (EglQuotePAVLight & AObject)[]) => {
                this.cartSrv.refreshCart();
                if (!res?.length) {
                    throw new Error('Errore aggiornamento PAV');
                }
                this.logger.info('PAV aggiornati con successo');
            }),
            map(() => true),
            catchError((error) => {
                this.logger.error(null, error?.message, null, true);
                return of(false);
            }),
        );
    }

    /**
     * Metodo temporaneo perchè deprecato
     * @param navigateTo
     * @param customerType
     */
    clearAndCreateNewCart$(navigateTo: any, customerType?: AptCustomerType): Observable<any> {
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('Empty Cart');
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map((cart) => cart as EglCartExtended),
            tap((currentCart) => this.cartSrv.deleteCart(currentCart)),
            mergeMap((currentCart) =>
                this.createNewCart(
                    new EglCartExtended(),
                    customerType || currentCart?.egl_customer_type,
                    navigateTo,
                ).pipe(share(), retry(3)),
            ),
            telemetryFinalizer,
            LoadingService.loaderOperator('Svuoto il carrello'),
        );
    }

    /**
     * Cancella il carrello attualmente in uso e ne crea uno nuovo
     * @param navigateTo: indica se dopo l'operazione bisogna fare redirect
     * @deprecated *** USARE CARTUTILITYSERVICE PER LA CREAZIONE DI NUOVO CARRELLO. TODO RIMUOVERE TUTTE LE CHIAMATE A QUESTO METODO ***
     */
    clearAndCreateNewCart(navigateTo: any, customerType?: AptCustomerType): void {
        this.clearAndCreateNewCart$(navigateTo, customerType).subscribe(
            () => this.clearState(),
            (err) => this.logger.error(null, err?.message, true, true),
        );
    }

    /**
     * @description: Crea un nuovo carrello partendo da un oggetto in input
     * @param cart: istanza carrello da creare
     * @param customerType: segmento carrello
     * @param navigateTo: se dopo la creazione deve essere effettuata una navigazione
     * @param reloadApp: se dopo la creazione deve essere ricaricata la pagina
     * @param clearState: se voglio resettare lo state Redux
     * @param showLoading: se dopo vuoi mostrare il messaggio 'Creazione nuovo carrello' durante l'elaborazione della richiesta
     * @deprecated *** USARE CARTUTILITYSERVICE PER LA CREAZIONE DI NUOVO CARRELLO. TODO RIMUOVERE TUTTE LE CHIAMATE A QUESTO METODO ***
     */
    createNewCart(
        cart: EglCartExtended = new EglCartExtended(),
        customerType?: AptCustomerType,
        navigateTo: any = null,
        reloadApp: boolean = false,
        clearState: boolean = false,
        showLoading: boolean = true,
        salesProcessCart?: string,
    ): Observable<EglCartExtended> {
        this.logger.info('Creating new cart...');

        return combineLatest([
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectAgentInfo),
            this.store.select(selectSalesProcess),
        ]).pipe(
            take(1),
            filter(([virtualAgent, agentInfo]) => !!virtualAgent && !!agentInfo),
            map(
                ([{ VirtualAgency }, { Agent, UserConfiguration }, salesProcess]: [
                    VirtualAgent,
                    AgentInfo,
                    AptSalesProcess,
                ]) => ({
                    virtualAgency: VirtualAgency,
                    agent: Agent,
                    salesProcess,
                    customerType: customerType || convertSegmentD365toApt(UserConfiguration?.LastUsedCustomerSegment),
                }),
            ),
            map(
                ({ virtualAgency, agent, salesProcess, customerType }): EglCartExtended =>
                    Object.assign(cart, {
                        egl_agency_code: virtualAgency?.Code,
                        egl_sales_channel: virtualAgency?.Channel?.Code,
                        egl_sales_process: salesProcessCart ? salesProcessCart : salesProcess,
                        egl_DAG_code: agent?.Code,
                        egl_customer_type: customerType,
                    }),
            ),
            mergeMap((data) =>
                reloadApp || navigateTo
                    ? this.router.navigate(reloadApp ? [RoutesPaths.Dashboard] : navigateTo).then(() => data)
                    : of(data),
            ),
            mergeMap((newCart) => this.cartSrv.createNewCart(newCart).pipe(take(1))),
            this.telemetrySrv.rxTelemetry('create-new-cart'),
            tap((newCart) => this.logger.info(`Activating cart id ${newCart?.Id}...`)),
            tap(() => this.cartSrv.refreshCart()),
            tap((activatedCart) => {
                this.logger.info(`Cart ${activatedCart?.Id} activated`);
                if (customerType) {
                    this.store.dispatch(setCartSegment({ payload: convertSegmentAptToD365(customerType) }));
                }
            }),
            tap(() => clearState && this.clearState()),
            tap((activatedCart) => {
                this.onCreateNewCartCompleted.emit(activatedCart?.Id);
            }),
            ifOp(showLoading, LoadingService.loaderOperator('Creazione nuovo carrello')),
            tap(() => reloadApp && window.location.reload()),
        ) as Observable<EglCartExtended>;
    }

    /* getter */

    /**
     * @deprecated NON USARE. Basarsi su valore presente nel local storage
     * @param cartId
     */
    setCartId(cartId: string): void {
        this._cartId = cartId;
    }

    /**
     * @deprecated NON USARE. Se proprio hai disogno di un cartId diverso da quello presente nel localStorage usa lo state. OrderEntry.cartId
     */
    get cartId(): string {
        return this._cartId || CartService.getCurrentCartId();
    }

    isItemRemovalInProgress(input: boolean): void {
        this.itemRemovalInProgress.next(input);
    }

    areConfigRulesLoaded(input: boolean): void {
        this.configRulesLoaded.next(input);
    }

    public clearState(clearCache = false, clearStateFlags = ClearStateFlags.OrderEntry | ClearStateFlags.User): void {
        if (clearCache) {
            this.cacheSrv
                .clear()
                .pipe(take(1))
                .subscribe(() => this.logger.info('Cache cleaned'));
        }

        if (EnumContainsFlag(clearStateFlags, ClearStateFlags.OrderEntry)) {
            this.store.dispatch(resetOrderEntry());
        }

        if (EnumContainsFlag(clearStateFlags, ClearStateFlags.User)) {
            this.store.dispatch(resetUserState());
        }
    }

    finalizeFlow$<R extends EglQuoteLight>(quote: R): Observable<R> {
        this.store.select(selectFlowType).subscribe((flowType) => (this.flowType = flowType));
        const isFlowTypeVocal =
            flowTypeUtil(this.flowType).equalTo(
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
                FlowType.Cessazione,
            ) && quote.egl_signature_mode === AptSignatureMode.VocalOrder;

        return (
            SKIP_DOCUMENT_GENERATION.includes(quote.egl_signature_mode) || isFlowTypeVocal
                ? of(null).pipe(
                      tap(() => {
                          this.logger.warn(
                              `Skip quote document generation. Quote signature_mode was: ${quote.egl_signature_mode}`,
                              false,
                          );
                      }),
                  )
                : this.recursiveCallToOpenText$(quote.Id)
        ).pipe(
            mergeMap(() => this.unlockFlow$(quote)),
            LoadingService.loaderOperator('Finalizzazione offerta'),
            catchError((err) => {
                this.logger.error(null, 'finalizeFlow_obs have an error', err, false);
                throw err;
            }),
        );
    }

    /**
     * @description: Attiva lo state model lato Apttus
     * @param quote: [quote] creata
     * @return: void
     */
    private unlockFlow$<R extends EglQuoteLight>(quote: R): Observable<R> {
        if (!quote) return of(null);

        this.logger.info('try to unlock flow...');
        quote.egl_flowunlock = true;
        return this.eglQuoteLightSrv.update([quote], false).pipe(
            map((res) => ((res || [])[0] || quote) as R),
            tap((quote) => this.logger.info('egl_flowunlock:', quote.egl_flowunlock)),
            catchError((err) => {
                this.logger.error(null, 'Error update quote on unlockFlow_obs', err, false);
                throw new Error('Error update quote on unlockFlow_obs');
            }),
            this.telemetrySrv.rxTelemetry('update-quote_egl_flowunlock'),
        );
    }

    /**
     * @description: Metodo ricorsivo generazione plico. A causa del set 'async' lato Apttus
     * le informazioni della quote potrebbero non essere disponibili al momento
     * della richiesta di generazione, causando eccezione. Questo metodo ritenta
     * ricorsivamente, per un max di 15 volte ogni 3 sec, di generare il plico
     */
    recursiveCallToOpenText$(quoteId: string, maxAttempts: number = 15): Observable<string> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            map((flowType) => ({
                quoteId,
                documentType: DOCUMENT_TYPE_CONFIG[flowType] || DOCUMENT_TYPE_CONFIG.DEFAULT,
            })),
            mergeMap(({ quoteId, documentType }) =>
                this.commonPrv.generateDocumentOT_obs(quoteId, documentType).pipe(
                    map((res) => {
                        this.logger.info(`quote documentType: ${documentType}`);
                        if (res?.status === StatusResponse.Success && res?.response?.fileUrl) {
                            return res?.response?.fileUrl;
                        }
                        throw new Error(`quote document not generated: ${documentType}`);
                    }),
                    tap((fileUrl) => {
                        this.logger.info(`document URL`, fileUrl);
                        this.store.dispatch(setPlicoUrl({ payload: fileUrl }));
                    }),
                    retryWithDelay(maxAttempts, 3000, `Errore generazione documento: ${documentType}`),
                    LoadingService.loaderOperator('Generazione documentazione in corso'),
                    this.telemetrySrv.rxTelemetry('plico-generated'),
                ),
            ),
        );
    }

    cartHaveQuote(cartId: string = CartService.getCurrentCartId()): Observable<{ quoteId: string; cartId: string }> {
        if (!cartId) return of(null);
        return this.commonPrv
            .query<
                SalesforceQueryResult<{
                    id: string;
                    Apttus_QPConfig__Proposald__c: string;
                }>
            >(
                `SELECT Id, Apttus_QPConfig__Proposald__c from Apttus_Config2__ProductConfiguration__c where id = '${cartId}'`,
            )
            .pipe(
                map(({ records }) =>
                    records[0]?.Apttus_QPConfig__Proposald__c
                        ? {
                              quoteId: records[0].Apttus_QPConfig__Proposald__c,
                              cartId,
                          }
                        : null,
                ),
            );
    }
 
    getEffectiveDate(orderId: string): Observable<{
        id: string;
        egl_Effective_date__c: string;
    }> {
        if (!orderId) return of(null);

        const query = `select Id, egl_Effective_date__c from Apttus_Config2__Order__c where Id = '${orderId}'`;
        const obs = this.commonPrv.query<
            SalesforceQueryResult<{
                id: string;
                egl_Effective_date__c: string;
            }>
        >(query.replace(' ', '+'));
        return obs.pipe(
            filter((res) => !!res && res.totalSize === 1),
            map((res) => res?.records[0]),
        );
    }

}
